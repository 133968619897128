import {
  abortNavigation,
  createError,
  defineNuxtRouteMiddleware,
  navigateTo,
} from "#imports";
import { isFullCase } from "~/src/models/Case/Case.model";
import { CaseStatus } from "~/src/models/Case/Shared.model";
import { useBookingService } from "~/src/services/BookingService";
import { useCaseService } from "~/src/services/CaseService";
import { useScreeningService } from "~/src/services/ScreeningService";
import { getDisplayFieldAndRouteParams } from "~/utils/pathHelpers";

import type { RouteLocationAsRelativeGeneric } from "#vue-router";
import type { BookingViewModel } from "~/src/models/Case/Booking.viewmodel";

const hitsPath = "cases-caseId-hits";
const individualCasePath = "cases-caseId";

function redirect(
  caseId: string,
  queryScreeningId: string | undefined,
  to: RouteLocationAsRelativeGeneric
) {
  to.query ??= {};
  to.query.redirect = "true";
  to.query.screeningId = queryScreeningId;
  to.params ??= {};
  to.params.caseId = caseId;
  return navigateTo(to);
}

function defaultNavigation(
  caseId: string,
  queryScreeningId: string | undefined,
  booking: BookingViewModel | null,
  hitField: string | undefined
) {
  if (booking === null) {
    return redirect(caseId, queryScreeningId, { name: hitsPath });
  }
  if (hitField) {
    const params = getDisplayFieldAndRouteParams(caseId, hitField)[1];
    return redirect(caseId, queryScreeningId, params);
  }
  if (booking.containers.length) {
    return redirect(caseId, queryScreeningId, {
      name: "cases-caseId-hits-containers-ctuId-cargo-cargoId",
      params: { ctuId: "1", cargoId: "1" },
    });
  }
  return redirect(caseId, queryScreeningId, {
    name: "cases-caseId-hits-booking",
  });
}

/**
 * general logic when changing cases:
 *
 * inspection cases go to /inspections
 *
 * try to stay within hit path (ex. if coming from containers try to stay within containers)
 *   try to go to the first entity in that category with a rule match or dg
 *   if there are no hits then just go to the first entity in that category
 *
 * if you can't stay within path (ex. you're trying to go to containers but there are no containers)
 *   then try to go to containers (and try to go the first with a hit)
 *   if that isn't possible then go to booking
 **/

// const fn: ArgumentsType<typeof defineNuxtRouteMiddleware>[0] = ;

export default defineNuxtRouteMiddleware(async (to, from) => {
  let toName = to.name as string;
  const { getCaseCached } = useCaseService();
  const { getBookingByScreeningIdCached } = useBookingService();
  const { listDgPredictionsCached } = useScreeningService();

  // I hate nuxt I hate nuxt I hate nuxt
  if (to.query.redirect === "true") return;

  // navigating between paths within the same case is handled on link creation
  if (
    to.query.history !== "true" &&
    from.params.caseId === to.params.caseId &&
    from.query.screeningId === to.query.screeningId
  )
    return;

  const caseId = decodeURIComponent(to.params.caseId as string);
  const caseView = await getCaseCached(caseId).catch(() => null);

  if (caseView == null) {
    return abortNavigation(
      createError({
        statusCode: 404,
        message: "Case not found",
      })
    );
  }

  // these paths are valid for every case
  if (
    toName === "cases-caseId-inspections" ||
    toName === "cases-caseId-history"
  )
    return;

  // inspection cases can only visit /inspections and /history
  if (!isFullCase(caseView)) {
    if (toName.startsWith(hitsPath) || toName === "cases-caseId") {
      return redirect(
        caseId,
        (to.query.screeningId ?? undefined) as string | undefined,
        {
          name: "cases-caseId-inspections",
        }
      );
    }
    return;
  }

  let firstField = caseView.latestScreening.hits.at(0)?.matches.at(0)?.field;

  const historical =
    typeof to.query.screeningId === "string" && to.query.screeningId.length > 0;
  const screeningId = historical
    ? (to.query.screeningId as string)
    : caseView.latestScreening.screeningId;
  const queryScreeningId = historical ? screeningId : undefined;

  if (firstField === undefined) {
    const dgs = await listDgPredictionsCached(screeningId).catch(() => null);
    if (dgs) {
      firstField =
        dgs.cargoTransportUnits.at(0)?.cargo.at(0)?.descriptions.at(0)?.path ??
        dgs.booking.descriptions.at(0)?.path;
    }
  }

  // coming from somewhere other than another case going to case/:caseId
  if (toName === individualCasePath) {
    if (
      caseView.caseStatus !== null &&
      (caseView.caseStatus.status === CaseStatus.InProgress ||
        caseView.caseStatus.status === CaseStatus.Closed)
    ) {
      return redirect(caseId, queryScreeningId, {
        name: "cases-caseId-history",
      });
    }

    const booking = await getBookingByScreeningIdCached(screeningId).catch(
      () => null
    );
    return defaultNavigation(caseId, queryScreeningId, booking, firstField);
  }

  const booking = await getBookingByScreeningIdCached(screeningId).catch(
    () => null
  );

  if (booking == null) {
    return redirect(caseId, queryScreeningId, {
      name: hitsPath,
    });
  }

  // /hits is only a valid path for cases without a booking
  if (toName === hitsPath) {
    return defaultNavigation(caseId, queryScreeningId, booking, firstField);
  }

  // removes "cases-caseId-hits-"
  toName = toName.slice(hitsPath.length + 1);

  if (toName.startsWith("sailings")) {
    if (booking.sailings.length > 0) {
      return redirect(caseId, queryScreeningId, {
        name: "cases-caseId-hits-sailings-sailingId",
        params: { sailingId: "1" },
      });
    } else
      return defaultNavigation(caseId, queryScreeningId, booking, firstField);
  }

  if (toName.startsWith("parties")) {
    if (Object.keys(booking._raw.parties).length > 0) {
      return redirect(caseId, queryScreeningId, {
        name: "cases-caseId-hits-parties",
      });
    } else
      return defaultNavigation(caseId, queryScreeningId, booking, firstField);
  }

  // if you're trying to go to containers go to the first container with a match (dg or rule)
  // or just go to container 0
  if (toName.startsWith("containers")) {
    if (booking.containers.length) {
      if (from.query.match !== "rule") {
        return redirect(caseId, queryScreeningId, {
          name: "cases-caseId-hits-containers-ctuId-cargo-cargoId",
          params: { ctuId: "1", cargoId: "1" },
          query: from.query,
        });
      }

      // todo check dg predictions
      const match = caseView.latestScreening.hits
        .flatMap((hit) => hit.matches)
        .find((match) => match.field.startsWith("$.c"));

      if (match === undefined) {
        return redirect(caseId, queryScreeningId, {
          name: "cases-caseId-hits-containers-ctuId-cargo-cargoId",
          params: { ctuId: "1", cargoId: "1" },
          query: { match: "rule" },
        });
      }

      const params = getDisplayFieldAndRouteParams(caseId, match.field)[1];
      params.query = { screeningId: queryScreeningId };
      return redirect(caseId, queryScreeningId, params);
    } else
      return defaultNavigation(caseId, queryScreeningId, booking, firstField);
  }

  return defaultNavigation(caseId, queryScreeningId, booking, firstField);
});
// export default defineNuxtRouteMiddleware(fn);
